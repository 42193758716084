import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    
}

export const businessSlice = createSlice({
    name: 'business/business',
    initialState,
    reducers: {
        setBusiness: (_, action) => action.payload
    },
})

export const { setBusiness } = businessSlice.actions

export default businessSlice.reducer
