import React, { useState } from 'react';
import {
  Notification
} from 'components/ui'

const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const addToast = (content, type) => {
    const newToast = (
      <Notification title={type.charAt(0).toUpperCase() + type.slice(1)} type={type}>
        {content}
      </Notification>
    );

    setToasts([...toasts, newToast]);
  };

  return (
    <div>
      {children}
      {toasts.map((toast, index) => (
        <div key={index}>{toast}</div>
      ))}
    </div>
  );
};

export default ToastProvider;
